import { useMutation, useQuery } from '@tanstack/react-query'
import {
  getCompanyName,
  getContactDetails,
  getContactPlaceholder,
  getMyProfile,
  qk,
} from 'api'
import { sendEmail } from 'api/mailbox'
import { EmailForm } from 'components/email'
import { ResizableWindowTemplate } from 'components/templates'
import { useConfirmationDialog } from 'lib/app-helpers'
import { renderAllQueriesResult } from 'lib/react-query-utils'
import { showToast } from 'lib/toast'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  isOpen: boolean
  onClose: () => void
  candidateId: string
  invitationUrl: string
  positionName?: string
}>

export const InviteToInterviewWindow = ({
  isOpen,
  onClose,
  candidateId,
  invitationUrl,
  positionName,
}: Props) => {
  const { t } = useTranslation()

  const $companyName = useQuery(qk.company.name.toKey(), getCompanyName)
  const $profile = useQuery(qk.auth.users.myProfile.toKey(), getMyProfile)

  const $positionPlaceholder = useQuery(
    qk.contacts.placeholder.toKeyWithArgs({ contactId: candidateId }),
    () => getContactPlaceholder({ contactId: candidateId }),
  )

  const $candidateDetails = useQuery(
    qk.contacts.details.toKeyWithArgs({ contactId: candidateId }),
    () => getContactDetails({ contactId: candidateId }),
    {
      onSuccess: candidate => {
        const isCandidateHasEmail = candidate.contactInfos.some(
          ({ type }) => type === 'EMAIL',
        )

        if (!isCandidateHasEmail) {
          showToast({
            title: t('toasts.add_contact_email_to_send_invitation'),
            type: 'error',
          })
          onClose()
        }
      },
    },
  )

  const $sendInvitation = useMutation(sendEmail, {
    onSuccess: () => {
      showToast({ title: t('toasts.interview_invitation_has_been_sent') })
      onClose()
    },
  })

  const confirmClose = useConfirmationDialog<void>(onClose)

  return renderAllQueriesResult(
    [$candidateDetails, $companyName, $profile, $positionPlaceholder],
    {
      loading: () => null,
      error: () => null,
      success: ([candidate, company, profile, positionPlaceholder]) => {
        const { placeholder: placeholderPositionName } = positionPlaceholder
        const candidateEmail = candidate.contactInfos.find(
          ({ type }) => type === 'EMAIL',
        )

        if (!candidateEmail) {
          return null
        }

        return (
          <>
            <ResizableWindowTemplate
              isOpen={isOpen}
              title={t('common.schedule_interview')}
              onClose={confirmClose.openDialog}
              position="fullscreen"
            >
              <EmailForm
                defaultValues={{
                  to: candidate.candidateProfileId,
                  subject: `Interview Invitation - ${candidate.position.name} at ${company.name}`,
                  body: `
                <p>Dear ${candidate.name}, </p>
                <br/>
                <p>We are pleased to invite you for an interview for the position of ${
                  positionName ?? placeholderPositionName
                }.</p>
                <p>Please choose the appropriate date and time by following the <a href="${invitationUrl}" rel="noopener noreferrer" target="_blank">scheduling link</a> </p>
                <p>We look forward to meeting you and discussing your qualifications further.</p>
                <p>Best regards, ${profile.fullName}</p>
                <p>${company.name}</p>
              `,
                }}
                onSubmit={values => {
                  $sendInvitation.mutate({ ...values, contactId: values.to })
                }}
                isLoading={$sendInvitation.isLoading}
                isToFieldDisabled={true}
                invitationUrl={invitationUrl}
                placeHolders={{
                  '[position]': positionName ?? placeholderPositionName,
                }}
              />
            </ResizableWindowTemplate>

            {confirmClose.renderConfirmDialog({
              size: 'small',
              title: t('common.changes_wont_be_saved'),
              children: t('confirms.cancel_interview_invitation_confirm'),
              variant: 'danger',
            })}
          </>
        )
      },
    },
  )
}
