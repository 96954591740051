import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { LinkButton } from 'ui/navigation'

type Props = {
  title: string
  description?: string
  editRoute?: string
  button?: ReactNode
  children: ReactNode
}

export const PageWrapper = ({
  title,
  description,
  editRoute,
  button,
  children,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Stack spacing={3} flex={1} minWidth={0}>
      <Helmet title={title} />

      <Box component="header" display="flex" justifyContent="space-between">
        <Stack spacing={2} mr={3}>
          <Typography variant="h2">{title}</Typography>

          {description && (
            <Typography variant="body2" color="mineShaft.800" maxWidth={720}>
              {description}
            </Typography>
          )}
        </Stack>

        {editRoute ? (
          <LinkButton to={editRoute}>{t('common.edit')}</LinkButton>
        ) : (
          button
        )}
      </Box>

      {children}
    </Stack>
  )
}
