import { Toolbar as MuiToolbar } from '@mui/material'
import { APP_LAYOUT_X_PADDING } from 'app/mui-theme/theme'

type Props = {
  children: React.ReactNode
  sticky?: boolean
  withGradient?: boolean
  transparent?: boolean
  noBorder?: boolean
  noPadding?: boolean
}

export const Toolbar = ({
  children,
  sticky,
  transparent,
  withGradient,
  noBorder,
  noPadding,
}: Props) => {
  let background = '#FFFFFF'
  if (transparent) background = 'transparent'
  if (withGradient)
    background = 'linear-gradient(300deg, #D08EF3 0%, #FFD7AE 100%)'

  return (
    <MuiToolbar
      sx={{
        minHeight: { xs: '64px' },
        paddingX: noPadding ? undefined : APP_LAYOUT_X_PADDING,
        flex: 'none',
        background: background,
        borderBottom: noBorder ? 0 : 1,
        borderColor: 'divider',
        display: 'flex',
        alignItems: 'center',

        ...(sticky && {
          position: 'sticky',
          top: 0,
          zIndex: theme => theme.zIndex.appBar - 1,
        }),
      }}
    >
      {children}
    </MuiToolbar>
  )
}
