import { Box, Stack, Typography } from '@mui/material'
import { ContactDetails } from 'api'
import { SkillBadge } from 'components/skills'
import { PurpleFadingGradientContainer } from 'ui/containers'
import { Avatar, BadgeCollection } from 'ui/data'
import { Toolbar } from 'ui/surfaces'

import { ContactBadgeCollection } from './contact-badge-collection'
import { ContactHeaderNavigation } from './contact-header-navigation'

type Props = Readonly<{
  contactDetails: ContactDetails
  contactId: string
}>

export const ContactHeader = ({ contactDetails, contactId }: Props) => {
  return (
    <PurpleFadingGradientContainer>
      <Toolbar transparent>
        <Box width="100%" mb={1}>
          <Box display="flex" alignItems="center">
            <Box position="relative">
              <Box>
                <Stack
                  direction="row"
                  alignItems="flex-end"
                  sx={{
                    transform: 'translate(0,-50%)',
                    mb: -6,
                  }}
                >
                  <Avatar size={120} src={contactDetails.imageUri} />
                  <Box mb={3} ml={2}>
                    <ContactBadgeCollection contactId={contactId} />
                  </Box>
                </Stack>

                <Typography variant="h2" sx={{ wordBreak: 'break-word' }}>
                  {contactDetails.name}
                </Typography>

                <Typography color="text.disabled">
                  {contactDetails.position.name} |{' '}
                  {contactDetails.qualificationLevel?.name}
                </Typography>

                {contactDetails.skills && contactDetails.skills.length > 0 && (
                  <Box my={2}>
                    <BadgeCollection>
                      {contactDetails.skills.map(skill => (
                        <SkillBadge
                          key={skill.skillId}
                          skillName={skill.skillName}
                          skillLevelCode={skill.skillLevelCode}
                        />
                      ))}

                      {/* TODO: NOT IMPLEMENTED */}
                      {/* <IconButton>
                        <IconPlusCircle color="primary" />
                      </IconButton> */}
                    </BadgeCollection>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            mt={2}
          >
            <ContactHeaderNavigation contactId={contactId} />
          </Box>
        </Box>
      </Toolbar>
    </PurpleFadingGradientContainer>
  )
}
