import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem as MuiTimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab'
import { Box } from '@mui/material'
import { ReactNode } from 'react'

type Props = Readonly<{
  children: ReactNode
  icon?: ReactNode
}>

export const TimelineItem = ({ children, icon }: Props) => {
  return (
    <MuiTimelineItem
      sx={{
        minHeight: 70,
        '&:last-child .MuiTimelineConnector-root': {
          display: 'none',
        },
      }}
    >
      <TimelineOppositeContent sx={{ display: 'none' }} />
      <TimelineSeparator>
        <TimelineDot
          sx={{
            mb: 0,
            width: icon ? 17 : 7,
            height: icon ? 17 : 7,
            p: 0,
            border: '1px solid',
            borderColor: 'mediumPurple.main',
            bgcolor: 'mediumPurple.main',
            borderRadius: icon ? '5px' : 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {icon}
        </TimelineDot>

        <TimelineConnector
          sx={{
            background: 'none',
            width: '1px',
            position: 'relative',
          }}
        >
          {/* <Stack alignItems="center"> */}
          <Box
            sx={{
              width: 4,
              height: 4,
              borderRadius: 100,
              position: 'absolute',
              right: '-1.5px',
              top: '5px',
              border: theme => `1px solid ${theme.palette.mediumPurple.main}`,
            }}
          />
          <Box
            sx={{
              height: 'calc(100% - 5px)',
              borderRight: '1px dashed',
              borderColor: 'mediumPurple.main',
              my: 1,
              mb: 0,
            }}
          />
          <Box
            sx={{
              width: 4,
              height: 4,
              borderRadius: 100,
              position: 'absolute',
              right: '-1.5px',
              border: theme => `1px solid ${theme.palette.mediumPurple.main}`,
            }}
          />

          {/* </Stack> */}
        </TimelineConnector>
      </TimelineSeparator>
      <TimelineContent sx={{ pl: 2, pr: 0, pt: 1.5, pb: 0 }}>
        {children}
      </TimelineContent>
    </MuiTimelineItem>
  )
}
