import { Stack, Typography } from '@mui/material'
import { DateRangePicker } from 'ui/inputs/date-range-picker'

type Props = {
  title: string
  dateFrom: Date | null
  onChangeDateFrom: (date: Date | null) => void
  dateTo: Date | null
  onChangeDateTo: (date: Date | null) => void
  minDate?: Date
  maxDate?: Date
}

export const DateRangeGroup = (props: Props) => {
  return (
    <Stack spacing={1}>
      <Typography variant="body1" color="mineShaft[900]">
        {props.title}
      </Typography>

      <DateRangePicker
        value={{ from: props.dateFrom, to: props.dateTo }}
        onChange={range => {
          props.onChangeDateFrom(range.from)
          props.onChangeDateTo(range.to)
        }}
        anchorDirection="left"
        openDirection="bottom"
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </Stack>
  )
}
