import { compareAsc, differenceInDays } from 'date-fns'
import { SetStateAction } from 'react'
import { DateRange } from 'react-day-picker'

import {
  DateRangeValue,
  END_INPUT,
  FocusedInput,
  START_INPUT,
} from './date-range-picker'

//These two functions are used to show range when one of dates are selected.
//For example, if from date is selected and you hover to any other date, days between them will be colored in light green

export const getHoveredFrom = (
  hoverDate: Date,
  selectedRange: DateRange,
  focusedInput: FocusedInput,
) => {
  if (selectedRange.to && !selectedRange.from) {
    return hoverDate
  }
  if (
    (selectedRange.to &&
      selectedRange.from &&
      compareAsc(selectedRange.from, selectedRange.to) !== 0) ||
    focusedInput === END_INPUT
  ) {
    return selectedRange.from
  }
  return hoverDate
}

export const getHoveredTo = (
  hoverDate: Date,
  selectedRange: DateRange,
  focusedInput: FocusedInput,
) => {
  if (selectedRange.from && !selectedRange.to) {
    return hoverDate
  }
  if (
    (selectedRange.from &&
      selectedRange.to &&
      compareAsc(selectedRange.from, selectedRange.to) !== 0) ||
    focusedInput === START_INPUT
  ) {
    return selectedRange.to
  }

  return hoverDate
}

//close dialog and clear data
export const handleClose = (
  setFocusedInput: (value: SetStateAction<FocusedInput>) => void,
  setAnchorEl: (value: SetStateAction<HTMLElement | null | undefined>) => void,
  setSelectedRange: (value: SetStateAction<DateRange>) => void,
) => {
  setFocusedInput(null)
  setAnchorEl(null)
  setSelectedRange({ from: undefined, to: undefined })
}

export const applyValue = (
  setFocusedInput: (value: SetStateAction<FocusedInput>) => void,
  setAnchorEl: (value: SetStateAction<HTMLElement | null | undefined>) => void,
  setSelectedRange: (value: SetStateAction<DateRange>) => void,
  selectedRange: DateRange,
  onChange: (value: DateRangeValue) => void,
) => {
  onChange({
    from: selectedRange.from ?? null,
    to: selectedRange.to ?? null,
  })
  handleClose(setFocusedInput, setAnchorEl, setSelectedRange)
}

export const onSelect = (
  range: DateRange | undefined,
  selectedRange: DateRange,
  setSelectedRange: (value: SetStateAction<DateRange>) => void,
  focusedInput: FocusedInput,
  setFocusedInput: (value: SetStateAction<FocusedInput>) => void,
  hoveredDate: Date | null,
) => {
  //Here are several dependencies to choose date. It checks if any of two dates are selected and if not checks which input is focused
  if (!selectedRange.from) {
    if (focusedInput === END_INPUT) {
      setSelectedRange({ to: range?.from!, from: undefined })
      setFocusedInput(START_INPUT)
    } else {
      //if user selected to and when selecting from chose date that is after to they will switch places
      if (compareAsc(selectedRange.to!, hoveredDate!) === -1) {
        setSelectedRange(prev => ({
          to: hoveredDate!,
          from: prev.to!,
        }))
      } else {
        setSelectedRange(prev => ({
          ...prev,
          from: hoveredDate!,
        }))
      }
      setFocusedInput(END_INPUT)
    }
  } else if (!selectedRange.to) {
    if (compareAsc(selectedRange.from!, hoveredDate!) === 1) {
      setSelectedRange(prev => ({
        from: hoveredDate!,
        to: prev?.from!,
      }))
    } else {
      setSelectedRange(prev => ({ ...prev!, to: hoveredDate! }))
    }
  } else {
    //This code works when both dates are selected. It checks if new date value is inside previous range or not.
    //If new date is inside the range then it checks which of two dates are closer 'from' or 'to' and updates closes one
    //If new date is outside the range, then range will be cleared and new date value will became from date
    if (
      compareAsc(hoveredDate!, selectedRange.from) >= 0 &&
      compareAsc(hoveredDate!, selectedRange.to) <= 0
    ) {
      const fromDifference = Math.abs(
        differenceInDays(selectedRange.from, hoveredDate!),
      )
      const toDifference = Math.abs(
        differenceInDays(selectedRange.to, hoveredDate!),
      )
      if (fromDifference < toDifference) {
        setSelectedRange(prev => ({
          ...prev,
          from: hoveredDate!,
        }))
      } else {
        setSelectedRange(prev => ({
          ...prev!,
          to: hoveredDate!,
        }))
      }
    } else {
      setSelectedRange({ from: hoveredDate!, to: undefined })
    }
  }
}

export const handleClick = (
  inputName: FocusedInput,
  setFocusedInput: (value: SetStateAction<FocusedInput>) => void,
  setAnchorEl: (value: SetStateAction<HTMLElement | null | undefined>) => void,
  setSelectedRange: (value: SetStateAction<DateRange>) => void,
  value: DateRangeValue,
  current: HTMLElement | undefined,
) => {
  setSelectedRange({
    from: value.from ?? undefined,
    to: value.to ?? undefined,
  })
  setAnchorEl(current)
  setFocusedInput(inputName)
}
