import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Typography } from '@mui/material'
import { RouteLeavingGuard } from 'components/global'
import { getInputError } from 'lib/form-utils'
import { useMemo } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { Checkbox } from 'ui/inputs/checkbox'
import { renderTextField, TextField } from 'ui/inputs/text-field'
import { z } from 'zod'

import { TemplateStagesTable } from './template-stages-table'

const STAGES_MINIMUM = 2
const STAGES_MAXIMUM = 7

const buildSchema = (t: TFunction) =>
  z.object({
    templateName: z.string().trim().min(3).max(25),
    newStageName: z.string().trim().min(3).max(30).or(z.literal('')),
    templateStages: z
      .array(
        z.object({
          name: z.string().trim().min(3).max(30),
          canHire: z.boolean(),
        }),
      )
      .refine(values => values.length >= STAGES_MINIMUM, {
        message: t('jobs.min_stages_message', {
          min: STAGES_MINIMUM,
        }),
      })
      .refine(
        values => {
          const stages = values.map(stage => stage.name)
          return new Set(stages).size === stages.length
        },
        { message: t('common.stages_duplicated') },
      ),
    default: z.boolean(),
  })

export type FormValues = z.infer<ReturnType<typeof buildSchema>>

// newStageName is purely internal form value
type FinalFormValues = Omit<FormValues, 'newStageName'>

type Props = Readonly<{
  formId: string
  onSubmit: (values: FinalFormValues) => void
  initialValues?: FinalFormValues
}>

const defaultValues: FormValues = {
  templateName: '',
  newStageName: '',
  templateStages: [],
  default: false,
}

export const PipelineTemplateForm = ({
  formId,
  onSubmit,
  initialValues,
}: Props) => {
  const { t } = useTranslation()

  const schema = useMemo(() => buildSchema(t), [t])

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    trigger,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: initialValues
      ? { ...initialValues, newStageName: '' }
      : defaultValues,
    resolver: zodResolver(schema),
  })

  const stages = useFieldArray({
    control,
    name: 'templateStages',
  })

  const isStagesMaximum = stages.fields.length === STAGES_MAXIMUM

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(values => {
        onSubmit({
          ...values,
          templateStages: values.templateStages.map((stage, index) => {
            // The last stage's canHire must always be true
            return index === values.templateStages.length - 1
              ? { ...stage, canHire: true }
              : stage
          }),
        })
      })}
    >
      <Controller
        control={control}
        name="templateName"
        render={renderTextField({
          label: t('common.template_name'),
        })}
      />
      <Typography variant="h3" mt={3} mb={1}>
        {t('common.template_stages')}
      </Typography>
      <Controller
        control={control}
        name="newStageName"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...getInputError(fieldState.error)}
            disabled={isStagesMaximum}
            placeholder={
              isStagesMaximum
                ? t('jobs.max_stages_message', {
                    max: STAGES_MAXIMUM,
                  })
                : t('common.stage_name_placeholder')
            }
            endAdornment={
              <Button
                disabled={isStagesMaximum}
                disableShadow
                inputsEndAdornment
                onClick={async () => {
                  if (field.value !== '') {
                    const isValid = await trigger('newStageName')
                    if (!isValid) return

                    const duplicate = stages.fields.find(
                      stage => field.value === stage.name,
                    )

                    if (duplicate) {
                      setError('newStageName', {
                        message: t('common.stage_duplicate'),
                      })
                    } else {
                      stages.append({
                        name: field.value,
                        canHire: false,
                      })
                      setValue('newStageName', '')
                    }
                  }
                }}
              >
                {t('common.add')}
              </Button>
            }
          />
        )}
      />
      {stages.fields.length > 0 && (
        <>
          <Box mt={3}>
            <TemplateStagesTable stages={stages} control={control} />
          </Box>

          <Typography variant="body2" color="text.secondary" mt={1}>
            {t('common.change_stage_number')}
          </Typography>

          {errors.templateStages && (
            <Typography variant="caption" color="error" mt={2}>
              {Array.isArray(errors.templateStages)
                ? t('validations.pipeline_stage_name_validation', {
                    minValue: 3,
                    maxValue: 15,
                  })
                : errors.templateStages.message}
            </Typography>
          )}
        </>
      )}
      <Box mt={3}>
        <Controller
          control={control}
          name="default"
          render={({ field }) => (
            <Checkbox
              checked={field.value}
              onChange={field.onChange}
              label={t('common.make_default')}
            />
          )}
        />
      </Box>
      <RouteLeavingGuard when={isDirty && !isSubmitSuccessful} />
    </form>
  )
}
