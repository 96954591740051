import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { IconPlusCircle } from 'assets/icons'
import { ReactNode } from 'react'
import { IconButton } from 'ui/inputs/icon-button'
import { Accordion } from 'ui/surfaces'

type Props = Readonly<
  {
    title: string
    children: ReactNode
    onAdd?: () => void
    addButtonTooltip?: string
  } & (
    | {
        withAccordion?: false
        expanded?: never
      }
    | {
        withAccordion?: true
        expanded?: boolean
      }
  )
>

export const DetailsItemContainer = ({
  title,
  children,
  withAccordion = false,
  expanded = true,
  addButtonTooltip,
  onAdd,
}: Props) => {
  if (withAccordion) {
    return (
      <Accordion
        title={
          <Typography variant="h4" mb={3} py={1.5}>
            {title}
          </Typography>
        }
        defaultExpanded={expanded}
      >
        {children}
      </Accordion>
    )
  }

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4" py={1.5}>
          {title}
        </Typography>

        {onAdd && (
          <Tooltip title={addButtonTooltip}>
            <IconButton onClick={onAdd}>
              <IconPlusCircle color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {children}
    </Box>
  )
}
