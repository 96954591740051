import { InputAdornment, useTheme } from '@mui/material'
import { IconCalendar } from 'assets/icons'

import { InputBase } from '../common'

type Props = {
  value?: string
  isFocused: boolean
  onClick: () => void
  placeholder: string
}

export const DateRangeInput = ({
  value,
  onClick,
  placeholder,
  isFocused,
}: Props) => {
  const theme = useTheme()

  return (
    <InputBase
      readOnly
      onClick={onClick}
      placeholder={placeholder}
      value={value}
      sx={{
        border: `1px solid ${
          isFocused ? theme.palette.primary.main : theme.palette.mineShaft[200]
        }`,
        ...(isFocused && {
          borderColor: theme.palette.primary.main,
          boxShadow: '0px 0px 4px 0px #00000040 inset, 0px 1px 4px 0px #9A7EF0',
        }),
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconCalendar
            sx={{ fontSize: '16px', color: theme.palette.mineShaft[800] }}
          />
        </InputAdornment>
      }
    />
  )
}
