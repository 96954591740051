export const ArrowLeftIcon = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      {...props}
      className="DayPickerNavigation_svg__horizontal DayPickerNavigation_svg__horizontal_1"
      focusable="false"
      viewBox="0 0 1000 1000"
    >
      <path
        fill="#82888a"
        d="M336 275L126 485h806c13 0 23 10 23 23s-10 23-23 23H126l210 210c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7L55 524c-11-11-11-21 0-32l249-249c21-22 53 10 32 32z"
      ></path>
    </svg>
  )
}

export const ArrowRightIcon = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      {...props}
      className="DayPickerNavigation_svg__horizontal DayPickerNavigation_svg__horizontal_1"
      focusable="false"
      viewBox="0 0 1000 1000"
    >
      <path
        fill="#82888a"
        d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"
      ></path>
    </svg>
  )
}
