import { InputAdornment, PopperPlacementType } from '@mui/material'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { IconCalendar } from 'assets/icons'
import { isValid } from 'date-fns/fp'
import { useBoolean } from 'lib/react-utils'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, FormControlWrapper, InputBase } from 'ui/inputs/common'
import { IconButton } from 'ui/inputs/icon-button'

import { CalendarPicker } from './calendar-picker'

export type Props = FormControlWrapper & {
  value: Date | null
  name: string
  onChange: (date: Date | null) => void

  small?: boolean
  fontSize?: string
  placement?: PopperPlacementType
  withoutIcon?: boolean
  showInvalidError?: boolean
  disableOpenPicker?: boolean
  disabled?: boolean
  disableDateBefore?: Date
  disableDateAfter?: Date
  disabledDates?: Array<Date>
  dataCy?: string
}

export const DatePicker = ({
  value,
  onChange,
  small,
  fontSize,
  placement,
  withoutIcon,
  showInvalidError,
  disableOpenPicker,
  label,
  error,
  helperText,
  disableDateBefore,
  disableDateAfter,
  disabledDates,
  disabled,
  required,
  dataCy,
  name,
}: Props) => {
  const { t } = useTranslation()

  const invalid = useBoolean(false)
  const isCalendarOpen = useBoolean(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [currentDate, setCurrentDate] = useState(value ?? new Date())

  const handleDateChange = (newDate: Date | null) => {
    if (showInvalidError) {
      if (newDate === null || isValid(newDate)) {
        invalid.setFalse()
      } else {
        invalid.setTrue()
      }
    }

    onChange(newDate)
  }

  return (
    <>
      <FormControl
        label={label}
        required={required}
        error={invalid.isTrue || error}
        helperText={invalid.isTrue ? t('validations.invalid_date') : helperText}
        name={name}
      >
        <MuiDatePicker
          disableOpenPicker
          value={value}
          onChange={handleDateChange}
          renderInput={params => (
            <InputBase
              {...params.InputProps}
              inputProps={{ ...params.inputProps, 'data-cy': dataCy }}
              ref={inputRef}
              id={label}
              small={small}
              disabled={disabled}
              sx={{ fontSize: fontSize }}
              error={invalid.isTrue || error}
              onClick={() => {
                if (withoutIcon && !disableOpenPicker && !disabled) {
                  isCalendarOpen.setTrue()
                }
              }}
              endAdornment={
                !withoutIcon && !disableOpenPicker && !disabled ? (
                  <InputAdornment position="end">
                    <IconButton onClick={isCalendarOpen.setTrue}>
                      <IconCalendar sx={{ fontSize: '16px' }} />
                    </IconButton>
                  </InputAdornment>
                ) : undefined
              }
            />
          )}
        />
      </FormControl>

      {!disableOpenPicker && !disabled && (
        <CalendarPicker
          isOpen={isCalendarOpen.isTrue}
          onClose={isCalendarOpen.setFalse}
          anchorElement={inputRef.current}
          placement={placement}
          disabledDates={disabledDates}
          disableDateBefore={disableDateBefore}
          disableDateAfter={disableDateAfter}
          date={isValid(currentDate) ? currentDate : null}
          onApply={() => {
            handleDateChange(currentDate)
            isCalendarOpen.setFalse()
          }}
          onChange={setCurrentDate}
        />
      )}
    </>
  )
}
