import { Box } from '@mui/material'
import { useBoolean } from 'lib/react-utils'
import { useEffect, useRef } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'

import { BUTTON_HEIGHT, CalendarButton } from './calendar-button'

const YEARS_COUNT = 200

const createYearsRange = () => {
  let firstYear = new Date().getFullYear() - YEARS_COUNT / 2
  return Array.from({ length: YEARS_COUNT }).map(() => firstYear++)
}

const yearsRange = createYearsRange()

const YearButton = ({
  index,
  style,
  data,
}: ListChildComponentProps<{
  year: number
  onChange: (value: number) => void
}>) => {
  const year = yearsRange[index]

  return (
    <Box style={style} px={1}>
      <CalendarButton
        onClick={() => {
          if (year) {
            data.onChange(year)
          }
        }}
        active={data.year === year}
      >
        {year}
      </CalendarButton>
    </Box>
  )
}

type Props = {
  year: number
  onChange: (year: number) => void
}

export const YearPicker = (props: Props) => {
  // react-window does not provide any types for list ref to use scrollToItem
  const listRef = useRef<any>(null)

  const currentYear = yearsRange.indexOf(props.year)
  const scrolled = useBoolean(false)

  useEffect(() => {
    if (currentYear !== -1 && scrolled.isFalse) {
      listRef.current.scrollToItem(currentYear, 'center')
      scrolled.setTrue()
    }
  }, [listRef, currentYear, scrolled])

  return (
    <FixedSizeList
      ref={listRef}
      width={100}
      height={BUTTON_HEIGHT * 12}
      itemCount={YEARS_COUNT}
      itemSize={BUTTON_HEIGHT}
      itemData={{ year: props.year, onChange: props.onChange }}
    >
      {YearButton}
    </FixedSizeList>
  )
}
