import {
  alpha,
  Box,
  ClickAwayListener,
  Divider,
  Fade,
  Popper,
  PopperPlacementType,
  Stack,
  useTheme,
} from '@mui/material'
import { CalendarPicker as MuiCalendarPicker } from '@mui/x-date-pickers'
import { isEqual, startOfDay } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'

import { MonthPicker } from './month-picker'
import { YearPicker } from './year-picker'

export type Props = {
  date: Date | null
  onChange: (date: Date) => void
  isOpen: boolean
  onClose: () => void
  anchorElement: HTMLElement | null
  onApply?: () => void
  placement?: PopperPlacementType
  hideYearMonthPickers?: boolean
  disableDateBefore?: Date
  disableDateAfter?: Date
  disabledDates?: Array<Date>
}

export const CalendarPicker = ({
  date,
  onChange,
  onApply,
  anchorElement,
  placement,
  isOpen,
  onClose,
  hideYearMonthPickers,
  disableDateBefore,
  disableDateAfter,
  disabledDates,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const currentDate = date ?? new Date()

  const disableDates = (date: Date) => {
    return (
      (!!(disableDateBefore && date < disableDateBefore) ||
        (disableDateAfter && date > disableDateAfter) ||
        (disabledDates &&
          disabledDates.some(datesItem =>
            isEqual(startOfDay(datesItem), startOfDay(date)),
          ))) ??
      false
    )
  }

  return (
    <Popper
      style={{
        zIndex: theme.zIndex.modal,
        borderRadius: '4px',
      }}
      open={isOpen}
      anchorEl={anchorElement}
      placement={placement}
      transition
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClose}>
          <Fade {...TransitionProps} timeout={200}>
            <Stack
              direction="row"
              divider={<Divider flexItem orientation="vertical" />}
              bgcolor="white"
              borderRadius="4px"
              boxShadow={`0px 1px 1px ${alpha(
                '#000000',
                0.05,
              )}, 0px 1px 4px ${alpha('#322D51', 0.16)}`}
              overflow="hidden"
              maxHeight="45vh"
            >
              {!hideYearMonthPickers && (
                <YearPicker
                  year={currentDate.getFullYear()}
                  onChange={year => {
                    onChange(new Date(currentDate.setFullYear(year)))
                  }}
                />
              )}

              {!hideYearMonthPickers && (
                <MonthPicker
                  month={currentDate.getMonth()}
                  onChange={month => {
                    onChange(new Date(currentDate.setMonth(month)))
                  }}
                />
              )}

              <Box position="relative">
                <Box maxHeight="calc(40vh - 10px)" overflow="auto">
                  <MuiCalendarPicker
                    views={['day']}
                    date={currentDate}
                    shouldDisableDate={disableDates}
                    onChange={newDate => {
                      if (newDate) {
                        onChange(newDate)
                      }
                    }}
                  />
                </Box>

                {onApply && (
                  <Stack alignItems="flex-end" pr="25px">
                    <Button onClick={onApply}>{t('common.apply')}</Button>
                  </Stack>
                )}
              </Box>
            </Stack>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  )
}
