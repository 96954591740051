import { Box } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getCompanySources, qk } from 'api'
import { ControlsLine, Fieldset } from 'components/common'
import { startOfDay } from 'date-fns'
import { getInputError } from 'lib/form-utils'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DatePicker } from 'ui/inputs/date-picker'
import { renderSelect } from 'ui/inputs/select'
import { renderTextField } from 'ui/inputs/text-field'

import { labels } from './_labels'
import { FormValues } from './_values'

type Props = {
  control: Control<FormValues>
  contactSource?: {
    name: string
    id: string
  }
  //educationName: string
}

export const PersonalInformation = ({ control, contactSource }: Props) => {
  const { t } = useTranslation()

  const $sources = useQuery(qk.company.sources.toKey(), getCompanySources)

  const sourcesOptionsFromQuery =
    $sources.data?.map(item => ({
      label: item.name,
      value: item.id,
    })) ?? []

  const isContactSourceOnOptionsList = sourcesOptionsFromQuery.some(
    ({ value }) => value === contactSource?.id,
  )

  const contactSourceOption = contactSource
    ? [{ value: contactSource.id, label: contactSource.name }]
    : []

  const sourcesOptions = isContactSourceOnOptionsList
    ? sourcesOptionsFromQuery
    : [...contactSourceOption, ...sourcesOptionsFromQuery]

  return (
    <Fieldset>
      <ControlsLine>
        <Controller
          name="name"
          control={control}
          render={renderTextField({
            required: true,
            label: t(labels.name).toString(),
            inputProps: { 'data-cy': 'nameInput' },
          })}
        />
      </ControlsLine>

      <ControlsLine>
        <Box minWidth={240} width="100%">
          <Controller
            name="birthDate"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <DatePicker
                  label={t(labels.birthDate).toString()}
                  value={field.value}
                  name="birthDate"
                  onChange={field.onChange}
                  disableDateAfter={startOfDay(new Date())}
                  {...getInputError(fieldState.error)}
                  dataCy="birthDateInput"
                />
              )
            }}
          />
        </Box>
        <Box width="100%" />
      </ControlsLine>

      <Controller
        name="sourceId"
        control={control}
        render={renderSelect({
          disableEmptyOption: true,
          loadingError: $sources.isError,
          label: t(labels.sourceId).toString(),
          //labelAdditionalElement: <SourcesConfigurationHint />,
          options: sourcesOptions,
          dataCy: 'sourceSelectInput',
        })}
      />
      {/*
      <Stack spacing={2} alignItems="start">
        <Controller
          name="educations.0.name"
          control={control}
          render={renderTextField({
            label: t(labels.educationName).toString(),
            inputProps: { 'data-cy': 'educationNameInput' },
          })}
        />

        <Controller
          name="educations.0.relatedToPosition"
          control={control}
          render={({ field }) => (
            <Checkbox
              checked={field.value ?? false}
              onChange={field.onChange}
              label={
                <Box display="flex">
                  {t('common.relevant_to_position')}
                  <InfoHint hint={t('hints.form_relevant_to_position')} />
                </Box>
              }
              dataCy="educationNameInput"
              disabled={educationName === ''}
            />
          )}
        />
      </Stack> */}
    </Fieldset>
  )
}
