import { GlobalStyles } from '@mui/material'

const outline = '1px solid #EDEEF2'
export const styleOverrides = ({
  isEmptyRange,
}: {
  isEmptyRange?: boolean
}) => (
  <GlobalStyles
    styles={{
      // Priority is higher than in date-range-overrides.css styles
      //Since react-day-picker is default functions are customized it does not work properly with hover css
      //So this style is necessary to override incorrect style

      ...(isEmptyRange && {
        '&&&  .rdp-day_selected,': {
          backgroundColor: '#e4e7e7',
          color: '#000000',
          border: 'none',
          outline: outline,
        },
        '&&& .rdp-day_selected:focus-visible': {
          backgroundColor: '#e4e7e7',
          color: '#000000',
          border: 'none',
          outline: outline,
        },
        '&&& .rdp-button:hover': {
          backgroundColor: '#e4e7e7',
          color: '#000000',
          border: 'none',
          outline: outline,
        },
        '&&& .rdp-day_selected:hover': {
          backgroundColor: '#e4e7e7',
          color: '#000000',
          border: 'none',
          outline: outline,
        },
        '&&& .rdp-button:hover:not([disabled])': {
          backgroundColor: '#e4e7e7 !important',
          color: 'black !important',
          border: 'none',
          outline: outline,
        },
        '&&& .rdp-day_range_end.rdp-day_range_start': {
          backgroundColor: '#e4e7e7 !important',
          color: 'black !important',
          border: 'none',
          outline: outline,
        },
      }),
    }}
  />
)
