import { Box } from '@mui/material'
import { months } from 'lib/js-utils'
import { useTranslation } from 'react-i18next'

import { CalendarButton } from './calendar-button'

type Props = {
  month: number
  onChange: (month: number) => void
}

export const MonthPicker = (props: Props) => {
  const { t } = useTranslation()
  return (
    <Box width={100} p={1} maxHeight="45vh" overflow="auto">
      {months.map((month, index) => (
        <CalendarButton
          key={index}
          onClick={() => props.onChange(index)}
          active={props.month === index}
        >
          {t(month).toString()}
        </CalendarButton>
      ))}
    </Box>
  )
}
