import { Box } from '@mui/material'
import { paths } from 'app/paths'
import { useScopes } from 'app/scopes'
import { FiltersTemplate } from 'components/templates'
import { useJobsFilters } from 'lib/app-helpers/jobs'
import { useBoolean } from 'lib/react-utils'
import { useTranslation } from 'react-i18next'
import { LinkButton } from 'ui/navigation'

import { JobsFilters } from './jobs-filters'
import { JobsTable } from './jobs-table'

export const JobsTableWithFilters = () => {
  const { t } = useTranslation()

  const {
    filters,
    isSelectedFiltersEmpty,
    applyFilters,
    resetFilters,
    pagination,
    order,
    isAppliedFiltersEmpty,
  } = useJobsFilters()

  const { canManageJob } = useScopes()

  const filtersOpen = useBoolean()

  return (
    <FiltersTemplate
      filtersContent={
        <JobsFilters
          filters={{
            statuses: {
              value: filters.statuses.state,
              setValue: filters.statuses.setState,
            },
            departments: {
              value: filters.departments.state,
              setValue: filters.departments.setState,
            },
            projects: {
              value: filters.projects.state,
              setValue: filters.projects.setState,
            },
            recruiters: {
              value: filters.recruiters.state,
              setValue: filters.recruiters.setState,
            },
            skills: {
              value: filters.skills.state,
              setValue: filters.skills.setState,
            },
            dateFrom: {
              value: filters.dateFrom.state,
              setValue: filters.dateFrom.setState,
            },
            dateTo: {
              value: filters.dateTo.state,
              setValue: filters.dateTo.setState,
            },
          }}
          onFiltersApply={applyFilters}
          onFiltersReset={resetFilters}
          onClose={() => filtersOpen.setFalse()}
          isSelectedFiltersEmpty={isSelectedFiltersEmpty}
        />
      }
    >
      <Box
        sx={{
          pl: 4,
          background: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <JobsTable
          statusesFilter={filters.statuses.appliedState}
          departmentsFilter={filters.departments.appliedState}
          projectsFilter={filters.projects.appliedState}
          recruitersFilter={filters.recruiters.appliedState}
          skillFilter={filters.skills.appliedState}
          dateFromFilter={filters.dateFrom.appliedState}
          dateToFilter={filters.dateTo.appliedState}
          pagination={pagination}
          initialOrder={order}
          isFiltersApplied={!isAppliedFiltersEmpty}
          actionButtons={
            canManageJob && (
              <LinkButton to={paths.createJob} size="large">
                {t('common.new_job')}
              </LinkButton>
            )
          }
        />
      </Box>
    </FiltersTemplate>
  )
}
