import { useMutation, useQuery } from '@tanstack/react-query'
import { generateInvitationLink, getUserIntegrations, qk } from 'api'
import { useCheckEmailIntegration } from 'lib/app-helpers/use-check-email-integration'
import { renderQueryResult } from 'lib/react-query-utils'
import { useBoolean } from 'lib/react-utils'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'react-i18next'

import { InviteToInterviewRenderer } from './invite-to-interview-renderer'

type Props = Readonly<{
  candidateId: string
  isContactHasEmail: boolean
  jobId?: string
  positionName?: string
  renderButton: ({
    isDisabled,
    disabledTooltipTitle,
    onClick,
  }: {
    isDisabled: boolean
    disabledTooltipTitle: string | null | JSX.Element
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  }) => ReactNode
}>

export const InviteToInterviewButton = ({
  candidateId,
  isContactHasEmail,
  positionName,
  jobId,
  renderButton,
}: Props) => {
  const { t } = useTranslation()

  const { integratedEmail, expiredHint } = useCheckEmailIntegration({
    isShortExpiredHint: true,
  })

  const $myIntegrations = useQuery(
    qk.auth.users.integrations.toKey(),
    getUserIntegrations,
  )

  const showRenderer = useBoolean()

  const [invitationUrl, setInvitationUrl] = useState<string | null>(null)

  const $generateInvitationLink = useMutation(generateInvitationLink, {
    onSuccess: ({ invitationUrl }) => {
      setInvitationUrl(invitationUrl)
    },
  })

  return renderQueryResult($myIntegrations, {
    loading: () => null,
    error: () => null,
    success: myIntegrations => {
      const isCalendarIntegrated = !!myIntegrations.some(
        integration => integration.type === 'CALENDAR',
      )

      const disabledTooltipTitle = getTooltipTitleForScheduleInterviewButton({
        isCalendarIntegrated,
        isContactHasEmail,
        expiredHint,
        t,
      })

      return (
        <>
          {renderButton({
            isDisabled:
              !isCalendarIntegrated ||
              !isContactHasEmail ||
              !!integratedEmail?.expired,
            disabledTooltipTitle,
            onClick: event => {
              event.stopPropagation()
              if (isCalendarIntegrated) {
                $generateInvitationLink.mutate(
                  {
                    contactId: candidateId,
                    jobId,
                  },
                  {
                    onSuccess: () => {
                      showRenderer.setTrue()
                    },
                    onError: () => {
                      $myIntegrations.refetch()
                    },
                  },
                )
              }
            },
          })}

          {showRenderer.isTrue && (
            <InviteToInterviewRenderer
              isOpen={showRenderer.isTrue}
              onClose={showRenderer.setFalse}
              candidateId={candidateId}
              positionName={positionName}
              invitationUrl={invitationUrl}
            />
          )}
        </>
      )
    },
  })
}

const getTooltipTitleForScheduleInterviewButton = ({
  isCalendarIntegrated,
  isContactHasEmail,
  expiredHint,
  t,
}: {
  isCalendarIntegrated: boolean
  isContactHasEmail: boolean
  expiredHint?: string | JSX.Element
  t: TFunction
}) => {
  if (expiredHint) return expiredHint

  if (isCalendarIntegrated) {
    return isContactHasEmail
      ? null
      : t('common.add_email_to_contact_for_schedule_interview')
  }

  return t('common.integrate_calendar_for_interview_invitation')
}
