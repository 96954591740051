import { ContactId } from 'api/contacts'
import { createListSearchParams, ListPagination } from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import { decodeJson, decodeJsonWithTotal, get, patch, post } from 'lib/request'

import {
  ThreadStatus,
  TMailboxEmailsCount,
  TMailboxThread,
  TMailboxThreadMessages,
} from './mailbox.codecs'

export type MailboxType = 'DRAFT' | 'INCOME' | 'OUTCOME'

export type GetMailboxThreadsInput = {
  pagination: ListPagination
  type: MailboxType
}

export type TNewEmail = ContactId & {
  subject: string
  body: string
}

export const getMailboxThreads = async ({
  pagination,
  type,
}: GetMailboxThreadsInput) => {
  const params = createListSearchParams({ pagination })
  params.set('type', type)
  return pipe(
    await get('messaging/mailboxes/threads', { query: params }),
    decodeJsonWithTotal(TMailboxThread, pagination),
  )
}

type ThreadId = {
  threadId: string
}

export type GetThreadDetailsInput = ThreadId

export const getMailboxThreadDetails = async ({
  threadId,
}: GetThreadDetailsInput) => {
  return pipe(
    await get('messaging/mailboxes/threads/:threadId', {
      params: { threadId },
    }),
    decodeJson(TMailboxThreadMessages),
  )
}

export const updateMailboxThreadStatus = async ({
  threadId,
  status,
}: ThreadId & {
  status: ThreadStatus
}) => {
  return await patch('messaging/mailboxes/threads/:threadId/status', {
    params: { threadId },
    body: { status },
  })
}

export const getMailboxUnreadEmailsCount = async () => {
  return pipe(
    await get('messaging/mailboxes/count', {
      query: new URLSearchParams({
        status: 'NOT_SEEN',
      }),
    }),
    decodeJson(TMailboxEmailsCount),
  )
}

export const sendEmail = async (input: TNewEmail) => {
  return pipe(await post('messaging/mailboxes', { body: input }))
}
