import './date-range-style.css'

import { Box, Popover, Stack, Tooltip, Typography } from '@mui/material'
import { IconArrowRestore } from 'assets/icons'
import {
  add,
  format,
  getDayOfYear,
  getQuarter,
  getWeek,
  isAfter,
  isBefore,
  isSameDay,
  isSameYear,
  isValid,
} from 'date-fns'
import { useRef, useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { DatePicker } from 'ui/inputs/date-picker'

import { ArrowLeftIcon, ArrowRightIcon } from './arrows'
import { commonDateRanges } from './common-date-ranges'
import { DateRangeInput } from './date-range-input'
import { styleOverrides } from './date-range-style-overrides'
import {
  applyValue,
  getHoveredFrom,
  getHoveredTo,
  handleClick,
  handleClose,
  onSelect,
} from './date-range-utils'

export type DateRangeValue = { from: Date | null; to: Date | null }
export type Props = {
  value: DateRangeValue
  onChange: (value: DateRangeValue) => void
  anchorDirection?: 'left' | 'right' | 'center'
  openDirection?: 'top' | 'bottom'
  minDate?: Date
  maxDate?: Date
  hideLastOptionsAccordingToDate?: Date
}
export const START_INPUT = 'start-input'
export const END_INPUT = 'end-input'
export type FocusedInput = typeof START_INPUT | typeof END_INPUT | null

export const DateRangePicker = ({
  value,
  onChange,
  anchorDirection = 'right',
  openDirection = 'top',
  minDate,
  maxDate,
  hideLastOptionsAccordingToDate,
}: Props) => {
  const [selectedRange, setSelectedRange] = useState<DateRange>({
    from: value.from ?? undefined,
    to: value.to ?? undefined,
  })

  const [hoveredDate, setHoveredDate] = useState<Date | null>(null)

  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null)

  const windowRef = useRef<HTMLElement>()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null | undefined>()

  const open = Boolean(anchorEl)

  const isPeriodValid =
    selectedRange.from && selectedRange.to
      ? isAfter(selectedRange.to, selectedRange.from) ||
        (minDate !== undefined && isBefore(selectedRange.from, minDate)) ||
        (maxDate !== undefined && isAfter(selectedRange.to, maxDate))
      : true

  const disabledDays = [
    {
      from: minDate ? add(minDate, { years: -100 }) : undefined,
      to: minDate ? add(minDate, { days: -1 }) : undefined,
    },
    {
      from: maxDate ? add(maxDate, { days: 1 }) : undefined,
      to: maxDate ? add(new Date(), { years: 100 }) : undefined,
    },
  ]

  return (
    <Box position="relative">
      {styleOverrides({
        isEmptyRange:
          selectedRange.from === undefined && selectedRange.to === undefined,
      })}
      <Stack direction="row" flexWrap="wrap" gap={2} ref={windowRef}>
        <DateRangeInput
          isFocused={focusedInput === END_INPUT || focusedInput === START_INPUT}
          onClick={() =>
            handleClick(
              START_INPUT,
              setFocusedInput,
              setAnchorEl,
              setSelectedRange,
              value,
              windowRef.current,
            )
          }
          placeholder={t('common.from')}
          value={
            selectedRange.from || value.from !== null
              ? format(selectedRange.from ?? value.from!, 'MM/dd/yyyy')
              : undefined
          }
        />

        <DateRangeInput
          isFocused={focusedInput === END_INPUT || focusedInput === START_INPUT}
          onClick={() =>
            handleClick(
              END_INPUT,
              setFocusedInput,
              setAnchorEl,
              setSelectedRange,
              value,
              windowRef.current,
            )
          }
          placeholder={t('common.to')}
          value={
            selectedRange.to || value.to !== null
              ? format(selectedRange.to ?? value.to!, 'MM/dd/yyyy')
              : undefined
          }
        />
      </Stack>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() =>
          handleClose(setFocusedInput, setAnchorEl, setSelectedRange)
        }
        sx={{
          mt: openDirection === 'top' ? 6 : -1.5,
          ml: anchorDirection === 'right' ? -11.5 : 0,
        }}
        PaperProps={{ variant: 'elevation' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: anchorDirection,
        }}
        transformOrigin={{
          vertical: openDirection,
          horizontal: 'left',
        }}
      >
        <Stack direction="row" gap={2} width="100%">
          <Stack borderRight={1} borderColor="divider" justifyContent="center">
            {commonDateRanges
              .filter(range => {
                if (!hideLastOptionsAccordingToDate) {
                  return true
                }

                const now = new Date()
                switch (range.id) {
                  case 'LAST_YEAR': {
                    return !isSameYear(now, hideLastOptionsAccordingToDate)
                  }
                  case 'LAST_QUARTER': {
                    return getQuarter(now) !== 1
                  }
                  case 'LAST_MONTH': {
                    return now.getMonth() !== 0
                  }
                  case 'LAST_WEEK': {
                    return getWeek(now) !== 1
                  }
                  case 'YESTERDAY': {
                    return getDayOfYear(now) !== 1
                  }
                  default: {
                    return true
                  }
                }
              })
              .map(currentRange => {
                const from = currentRange.from
                const to = currentRange.to
                const isActive =
                  isSameDay(from, selectedRange.from!) &&
                  isSameDay(to, selectedRange.to!)

                return (
                  <Button
                    key={currentRange.name}
                    variant={isActive ? 'contained' : 'text'}
                    color={isActive ? 'primary' : 'greyBlue'}
                    onClick={() => {
                      setSelectedRange({ from, to })
                    }}
                    sx={{
                      borderRadius: 0,
                      typography: 'caption',
                      textAlign: 'left',
                      textTransform: 'none',
                      outline: 'none',
                    }}
                  >
                    <Typography variant="inherit">
                      {t(currentRange.name) as string}
                    </Typography>
                  </Button>
                )
              })}
          </Stack>
          <Stack
            justifyContent="space-between"
            gap={2.5}
            pt={2.5}
            pb={4}
            pl={1}
            pr={2.75}
          >
            <DayPicker
              className="Selectable"
              id="test"
              mode="range"
              numberOfMonths={2}
              disabled={disabledDays}
              defaultMonth={new Date()}
              weekStartsOn={0}
              selected={{
                from: getHoveredFrom(hoveredDate!, selectedRange, focusedInput),
                to: getHoveredTo(hoveredDate!, selectedRange, focusedInput),
              }}
              onDayMouseLeave={() => setHoveredDate(null)}
              onSelect={range =>
                onSelect(
                  range,
                  selectedRange,
                  setSelectedRange,
                  focusedInput,
                  setFocusedInput,
                  hoveredDate,
                )
              }
              onDayMouseEnter={date => setHoveredDate(date)}
              components={{
                IconLeft: ArrowLeftIcon,
                IconRight: ArrowRightIcon,
              }}
            />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={2}
              width={530}
              overflow="auto"
            >
              <DatePicker
                label={t('common.date_range_periods.start_date')}
                value={selectedRange.from ?? null}
                name="startDate"
                disableOpenPicker
                onChange={date => {
                  if (date !== null && isValid(date))
                    setSelectedRange(prev => ({ ...prev, from: date }))
                }}
              />

              <DatePicker
                label={t('common.date_range_periods.end_date')}
                value={selectedRange.to ?? null}
                name="endDate"
                disableOpenPicker
                onChange={date => {
                  if (
                    date !== null &&
                    isValid(date) &&
                    selectedRange.from !== null
                  )
                    setSelectedRange(prev => ({ ...prev!, to: date }))
                }}
              />

              <Box flex="none">
                <Tooltip
                  title={t('common.date_range_periods.reset_dates')}
                  arrow
                  placement="top"
                >
                  <Button
                    icon
                    variant="outlined"
                    color="greyBlue"
                    onClick={() => {
                      setSelectedRange({ from: undefined, to: undefined })
                    }}
                  >
                    <IconArrowRestore sx={{ fontSize: '16px' }} />
                  </Button>
                </Tooltip>
              </Box>

              <Button
                variant="outlined"
                color="greyBlue"
                onClick={() => {
                  handleClose(setFocusedInput, setAnchorEl, setSelectedRange)
                }}
              >
                {t('common.cancel')}
              </Button>

              <Button
                disabled={!isPeriodValid}
                onClick={() =>
                  applyValue(
                    setFocusedInput,
                    setAnchorEl,
                    setSelectedRange,
                    selectedRange,
                    onChange,
                  )
                }
              >
                {t('common.apply')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Popover>
    </Box>
  )
}
