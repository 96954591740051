import { Box, Stack, Typography } from '@mui/material'
import { TFunction } from 'i18next'
import { CircularProgressbar } from 'react-circular-progressbar'
import { useTranslation } from 'react-i18next'

import { FormValues } from '../_values'

type Props = Readonly<{
  watchedFields: FormValues
}>

export const ProfileCompleteness = ({ watchedFields }: Props) => {
  const { t } = useTranslation()

  const {
    // completenessDetails,
    completenessValue,
    completenessStatus,
    completenessColor,
  } = getCompletenessResult(watchedFields, t)

  return (
    <>
      {/* <Paper sx={{ p: 1.5 }}>
        <Fieldset
          legend={t('common.profile_completeness')}
          hint={t('hints.profile_completeness')}
        > */}
      <Stack
        alignItems="center"
        bgcolor="alabaster"
        spacing="12px"
        padding="16px"
        borderRadius="6px"
      >
        <Box position="relative" height={100}>
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -25%)',
            }}
          >
            <CircularProgressbar
              value={completenessValue}
              circleRatio={0.5}
              strokeWidth={5}
              styles={{
                root: {
                  transform: 'rotate(0.75turn)',
                  width: 180,
                },
                path: {
                  stroke: completenessColor,
                  strokeLinecap: 'round',
                },
                trail: { stroke: '#FFFFFF', strokeLinecap: 'round' },
              }}
            />
          </Box>

          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              bottom: '0',
              transform: 'translateX(-50%)',
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
              gap={0.5}
            >
              <Typography variant="h1">{completenessValue}</Typography>
              <Typography pb="4px">%</Typography>
            </Stack>
            <Typography color={completenessColor} variant="body1">
              {completenessStatus}
            </Typography>
          </Box>
        </Box>

        <Typography
          variant="caption"
          textAlign="center"
          maxWidth="144px"
          width="100%"
          color="mineShaft.700"
        >
          {t('hints.profile_completeness')}
        </Typography>
      </Stack>

      {/*
        <Accordion
          title={
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <Typography variant="h4" fontWeight={500}>
                {t('common.details')}
              </Typography>
            </Stack>
          }
          defaultExpanded={false}
        >
          <List>
            {completenessDetails.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconCheckVerified01
                    sx={{
                      color: item.isFilled
                        ? 'mediumPurple.main'
                        : 'text.secondary',
                    }}
                  />
                  <Typography>{item.name}</Typography>
                </Stack>
                <Typography>{item.percent}%</Typography>
              </ListItem>
            ))}
          </List>
        </Accordion> */}
      {/* </Fieldset>
      </Paper> */}
    </>
  )
}

const getCompletenessResult = (watchedFields: FormValues, t: TFunction) => {
  const completenessDetails = [
    {
      name: t('common.name'),
      percent: 6,
      isFilled: !!watchedFields.name,
    },
    {
      name: t('common.birthday'),
      percent: 0,
      isFilled: !!watchedFields.birthDate,
    },
    {
      name: t('common.education'),
      percent: 4,
      isFilled: watchedFields.educations && watchedFields.educations.length > 0,
    },
    {
      name: t('common.profile_image'),
      percent: 3,
      isFilled: !!watchedFields.image,
    },
    {
      name: t('common.country'),
      percent: 6,
      isFilled: !!watchedFields.country,
    },
    {
      name: t('common.city'),
      percent: 5,
      isFilled: !!watchedFields.city,
    },
    {
      name: t('common.email'),
      percent: 10,
      isFilled:
        watchedFields.contactInfos &&
        watchedFields.contactInfos.some(
          info => info.type === 'EMAIL' && !!info.value,
        ),
    },
    {
      name: t('common.phone'),
      percent: 2,
      isFilled:
        watchedFields.contactInfos &&
        watchedFields.contactInfos.some(
          info => info.type === 'PHONE' && !!info.value,
        ),
    },
    {
      name: t('common.messenger'),
      percent: 2,
      isFilled:
        watchedFields.contactInfos &&
        watchedFields.contactInfos.some(
          info => info.type === 'MESSENGER' && !!info.value,
        ),
    },
    {
      name: t('common.position'),
      percent: 3,
      isFilled: !!watchedFields.position,
    },
    {
      name: t('common.qualification_level'),
      percent: 5,
      isFilled: !!watchedFields.qualificationLevel,
    },
    {
      name: t('common.notes'),
      percent: 0,
      isFilled: !!watchedFields.note,
    },
    {
      name: t('common.skills'),
      percent: 10,
      isFilled: watchedFields.skills && watchedFields.skills.length > 0,
    },
    {
      name: t('common.languages'),
      percent: 10,
      isFilled: watchedFields.languages && watchedFields.languages.length > 0,
    },
    {
      name: t('common.links'),
      percent: 10,
      isFilled: watchedFields.links && watchedFields.links.length > 0,
    },
    {
      name: t('common.source'),
      percent: 10,
      isFilled: !!watchedFields.sourceId,
    },
    {
      name: t('common.documents'),
      percent: 10,
      isFilled: watchedFields.documents && watchedFields.documents.length > 0,
    },
    {
      name: t('common.work_experience'),
      percent: 4,
      isFilled:
        watchedFields.workExperience && watchedFields.workExperience.length > 0,
    },
  ]

  const completenessValue = completenessDetails.reduce((acc, detail) => {
    return acc + (detail.isFilled ? detail.percent : 0)
  }, 0)

  const completenessStatus = getCompletenessStatus(completenessValue, t)

  const completenessColor = getCompletenessColor(completenessValue)

  return {
    completenessDetails,
    completenessValue,
    completenessStatus,
    completenessColor,
  }
}

const getCompletenessStatus = (value: number, t: TFunction) => {
  switch (true) {
    case value < 26: {
      return t('contacts.contacts_completeness.INCOMPLETE')
    }
    case value < 51: {
      return t('contacts.contacts_completeness.MINIMAL')
    }
    case value < 76: {
      return t('contacts.contacts_completeness.ACCEPTABLE')
    }
    default: {
      return t('contacts.contacts_completeness.COMPLETE')
    }
  }
}

const getCompletenessColor = (value: number) => {
  switch (true) {
    case value < 26: {
      return '#D42828'
    }
    case value < 51: {
      return '#FF8200'
    }
    case value < 76: {
      return '#FFA84D'
    }
    default: {
      return '#00B065'
    }
  }
}
