import { forwardRef, ReactNode } from 'react'
import { Button } from 'ui/inputs/button'

type Props = {
  children: ReactNode
  onClick: () => void
  active: boolean
}

export const BUTTON_HEIGHT = 32

export const CalendarButton = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    return (
      <Button
        ref={ref}
        onClick={props.onClick}
        variant={props.active ? 'contained' : 'text'}
        fullWidth
        sx={{
          maxHeight: `${BUTTON_HEIGHT}px`,
          textTransform: 'none',
          fontWeight: 'medium',
          transition: 'none',
          outline: 'none',

          ...(!props.active && {
            color: 'text.primary',
          }),
        }}
      >
        {props.children}
      </Button>
    )
  },
)
