import './text-editor-styles.css'

import { forwardRef } from 'react'
import ReactQuill from 'react-quill'
import { FormControl } from 'ui/inputs/common'

type Props = Readonly<{
  value: string
  onChange: (value: string) => void
  isToolbarHidden?: boolean
  label?: string
  onSelectionChange?: (range: ReactQuill.Range | null) => void
}>

export const EmailBodyTextEditor = forwardRef<ReactQuill, Props>(
  ({ value, onChange, isToolbarHidden, label, onSelectionChange }, ref) => {
    const handleSelection = (range: ReactQuill.Range) => {
      if (onSelectionChange) {
        onSelectionChange(range)
      }
    }

    return (
      <FormControl label={label}>
        <ReactQuill
          ref={ref}
          theme="snow"
          value={value}
          onChange={onChange}
          onChangeSelection={handleSelection}
          className={[
            'email-body',
            isToolbarHidden ? 'email-body--hidden-toolbar' : '',
            'ql-email-height',
          ].join(' ')}
          modules={{
            // Toolbar options are slightly reduced in size through styles to fit on a single line.
            // This is done to avoid adding a custom dropdown with options that don't fit,
            // because that would require a lot more effort to customize (doubt it's even possible)
            toolbar: [
              { font: [] },
              { size: [] },
              'bold',
              'italic',
              'underline',
              { color: [] },
              { align: [] },
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
              'blockquote',
              'strike',
              'link',
              'clean',
            ],
          }}
        />
      </FormControl>
    )
  },
)
