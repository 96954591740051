import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack } from '@mui/material'
import { ContactInformationType } from 'api'
import { useDictionaries } from 'lib/app-helpers'
import { getInputError } from 'lib/form-utils'
import { contactInformationLabels } from 'lib/records'
import { useMemo } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'ui/feedback'
import { renderAutocompleteFreeSolo } from 'ui/inputs/autocomplete'
import { Button } from 'ui/inputs/button'
import { PhoneInput } from 'ui/inputs/phone-input'
import { renderSelect } from 'ui/inputs/select'
import { TextField } from 'ui/inputs/text-field'
import { z } from 'zod'

import { getContactInfoByTypeSchema } from './create-dialog'

type ContactInfos = Array<{ value: string; label: string }>

const buildSchema = (type: ContactInformationType, t: TFunction) => {
  return z.object({
    contactInfos: z.array(getContactInfoByTypeSchema(type, t)),
  })
}

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (values: ContactInfos) => void
  initialValues: ContactInfos
  type: ContactInformationType
  defaultCountry: string
}

export const EditContactInformationDialog = (props: Props) => {
  const { t } = useTranslation()

  const { dictionaries, isDictionariesError } = useDictionaries()

  const schema = useMemo(() => buildSchema(props.type, t), [props.type, t])

  const { control, handleSubmit, reset } = useForm<z.infer<typeof schema>>({
    defaultValues: { contactInfos: props.initialValues },
    resolver: zodResolver(schema),
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contactInfos',
  })

  const closeDialog = () => {
    reset()
    props.onClose()
  }

  return (
    <Dialog open={props.isOpen} onClose={closeDialog}>
      <DialogContent
        title={t(`contacts.contact_information_types.${props.type}`).toString()}
        onConfirm={handleSubmit(values => {
          props.onSubmit(values.contactInfos)
          closeDialog()
        })}
        onDeny={closeDialog}
        confirmText={t('common.save')}
        wrapWithForm
      >
        <Stack spacing={3}>
          {fields.map((field, index) => (
            <Stack key={field.id} spacing={2} direction="row">
              <Box width="calc(100% - 48px)">
                {props.type === 'MESSENGER' ? (
                  <Controller
                    control={control}
                    name={`contactInfos.${index as 0}.label` as const}
                    defaultValue={field.label}
                    render={renderSelect({
                      placeholder: t('common.enter_label'),
                      disableEmptyOption: true,
                      label: t('common.label'),
                      disableAlphabeticalOrder: true,
                      loadingError: isDictionariesError,
                      options: dictionaries.messengerTypes.options,
                      dataCy: 'selectMessengerLabelInput',
                    })}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={`contactInfos.${index as 0}.label` as const}
                    defaultValue={field.label}
                    render={renderAutocompleteFreeSolo({
                      placeholder: t('common.enter_label'),
                      label: t('common.label'),
                      options: contactInformationLabels.map(value => {
                        const label = t(
                          `contacts.contact_information_block.labels.${value}`,
                        )
                        return { value: label, label }
                      }),
                      dataCy: 'selectContactLabelInput',
                    })}
                  />
                )}
              </Box>

              <Controller
                control={control}
                name={`contactInfos.${index as 0}.value` as const}
                defaultValue={field.value}
                render={({ field, fieldState }) => {
                  const valueProps = {
                    value: field.value,
                    onChange: field.onChange,
                    label: t('common.value'),
                    onRemove: () => remove(index),
                    ...getInputError(fieldState.error),
                  }

                  return props.type === 'PHONE' ? (
                    <PhoneInput
                      defaultCountry={props.defaultCountry}
                      {...valueProps}
                      dataCy="contactPhoneValueInput"
                    />
                  ) : (
                    <TextField
                      placeholder={t('common.enter_value')}
                      {...valueProps}
                      inputProps={{ 'data-cy': 'contactValueInput' }}
                    />
                  )
                }}
              />
            </Stack>
          ))}
        </Stack>

        <Button
          variant="outlined"
          sx={{ position: 'absolute', top: '20px', right: '24px' }}
          onClick={() => {
            append({ label: '', value: '' })
          }}
          data-cy="addContactInfoButton"
        >
          + {t('common.add')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
