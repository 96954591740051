import { Paper, Skeleton, Stack } from '@mui/material'
import { range } from 'fp-ts/lib/NonEmptyArray'

export const HistorySkeleton = () => {
  return (
    <Paper sx={{ p: 3, mx: 'auto', width: '100%', maxWidth: 1160 }}>
      <Stack spacing={4.5}>
        {range(0, 8).map((_, index) => (
          <Stack key={index} direction="row" alignItems="center" spacing={2}>
            <Skeleton variant="text" width="10%" />
            <Skeleton variant="text" width={`${50 + (index % 3) * 10}%`} />
          </Stack>
        ))}
      </Stack>
    </Paper>
  )
}
