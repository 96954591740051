import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Divider, Stack } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  CompanyDetails,
  ContactDetails,
  getContactDetails,
  qk,
  User,
} from 'api'
import { EmailTemplate } from 'api/email-templates'
import { useScopes } from 'app/scopes'
import { IconFormatting } from 'assets/icons'
import { ValidationErrors } from 'components/common'
import { ContactAutocomplete } from 'components/contacts'
import { ResizableWindowIconButton } from 'components/templates'
import { getInputError } from 'lib/form-utils'
import { useBoolean } from 'lib/react-utils'
import { DataSources, replaceFieldsWithPlaceholders } from 'lib/records'
import { showToast } from 'lib/toast'
import { labels } from 'pages/activities/form/_values'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { renderTextField } from 'ui/inputs/text-field'
import { z } from 'zod'

import { AddTemplateButton } from './add-template-button'
import { EmailBodyTextEditor } from './email-body-text-editor'

const schema = z.object({
  to: z.string().min(1),
  subject: z.string().min(1),
  body: z.string(),
})

type FormValues = z.infer<typeof schema>

export type EmailFormProps = Readonly<{
  defaultValues: FormValues
  onSubmit: (values: FormValues) => void
  isLoading: boolean
  isToFieldDisabled?: boolean
  invitationUrl?: string
  placeHolders?: Partial<DataSources>
}>

type SelectEmailTemplate = {
  emailSubject: string
  emailBody: string
}

export const EmailForm = ({
  defaultValues,
  onSubmit,
  isLoading,
  isToFieldDisabled,
  invitationUrl,
  placeHolders,
}: EmailFormProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { canViewEmailTemplate } = useScopes()

  const [selectedTemplate, setSelectedTemplate] =
    useState<SelectEmailTemplate | null>(null)

  const authUser = queryClient.getQueryData<User>(
    qk.auth.users.myProfile.toKey(),
  )

  const companyDetails = queryClient.getQueryData<CompanyDetails>(
    qk.company.details.toKey(),
  )

  const isFormattingOptionsVisible = useBoolean(true)

  const { control, handleSubmit, setValue, watch } = useForm<
    z.infer<typeof schema>
  >({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const contactId = watch('to')
  const { data: contact } = useQuery(
    qk.contacts.details.toKeyWithArgs({ contactId }),
    () => getContactDetails({ contactId }),
    {
      enabled: !!contactId,
      onSuccess: contactData => {
        if (selectedTemplate) {
          applyTemplate(selectedTemplate, contactData)
        }
      },
    },
  )

  const applyTemplate = useCallback(
    (
      { emailBody, emailSubject }: SelectEmailTemplate,
      newSelectedContact?: ContactDetails,
    ) => {
      setSelectedTemplate(() => ({ emailSubject, emailBody }))

      // determine which contact details to use for template:
      // - if new contact was passed to the arguments then use it (contact was selected after template using)
      // - otherwise use details from contact that was selected before template using
      const contactDetails = newSelectedContact ?? contact

      const contactEmail = contactDetails?.contactInfos.find(
        item => item.type === 'EMAIL',
      )

      const dataSources: DataSources = {
        '[recipient_name]':
          placeHolders?.['[recipient_name]'] || contactDetails?.name || '',
        '[recipient_email]':
          placeHolders?.['[recipient_email]'] || contactEmail?.value || '',
        '[position]':
          placeHolders?.['[position]'] || contactDetails?.position.name || '',
        '[email_sender_address]':
          placeHolders?.['[email_sender_address]'] || authUser?.email || '',
        '[email_sender_name]':
          placeHolders?.['[email_sender_name]'] || authUser?.fullName || '',
        '[company_name]':
          placeHolders?.['[company_name]'] || companyDetails?.name || '',
        '[scheduling_link]':
          placeHolders?.['[scheduling_link]'] || invitationUrl || '',
      }

      const [replacedEmailSubject, replacedEmailBody] =
        replaceFieldsWithPlaceholders(
          [emailSubject, emailBody],
          dataSources,
        ) as [string, string]

      setValue('subject', replacedEmailSubject)
      setValue('body', replacedEmailBody)
    },
    [
      authUser?.email,
      authUser?.fullName,
      companyDetails?.name,
      contact,
      invitationUrl,
      placeHolders,
      setValue,
    ],
  )

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit, errors => {
        const required = ['to', 'subject']

        showToast(
          {
            type: 'error',
            title: t('toasts.form_not_completed'),
            body: (
              <ValidationErrors
                errors={errors}
                translations={labels}
                required={required}
              />
            ),
          },
          { autoClose: false },
        )
      })}
    >
      <Box component="main" px={3} pb={3}>
        <Stack spacing={3}>
          <Controller
            control={control}
            rules={{ required: true }}
            name="to"
            render={({ field, fieldState }) => (
              <ContactAutocomplete
                {...field}
                value={field.value}
                {...getInputError(fieldState.error)}
                required
                disableAlphabeticalOrder
                disabled={isToFieldDisabled}
                label={t('common.to')}
              />
            )}
          />

          <Controller
            control={control}
            rules={{ required: true }}
            name="subject"
            render={renderTextField({
              label: t('common.subject'),
              required: true,
            })}
          />

          <Controller
            control={control}
            name="body"
            render={({ field }) => (
              <EmailBodyTextEditor
                value={field.value}
                onChange={field.onChange}
                label={t('common.body')}
                isToolbarHidden={isFormattingOptionsVisible.isFalse}
              />
            )}
          />
        </Stack>
      </Box>

      <Divider />

      <Box
        component="footer"
        sx={{
          p: 3,
          pt: 2.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" spacing={1}>
          <ResizableWindowIconButton
            tooltip={t('common.formatting_options')}
            active={isFormattingOptionsVisible.isTrue}
            onClick={isFormattingOptionsVisible.toggle}
          >
            <IconFormatting />
          </ResizableWindowIconButton>

          {canViewEmailTemplate && (
            <AddTemplateButton
              onSelect={({ emailSubject, emailBody }: EmailTemplate) => {
                applyTemplate({ emailSubject, emailBody })
              }}
            />
          )}
        </Stack>

        <Button type="submit" loading={isLoading}>
          {t('common.send')}
        </Button>
      </Box>
    </form>
  )
}
