import { useQuery } from '@tanstack/react-query'
import {
  getCompanyDepartments,
  getCompanyProjects,
  getCompanySkillsList,
  getUsersList,
  qk,
} from 'api'
import { DateRangeGroup, Filters } from 'components/common/filters'
import { useDictionaries } from 'lib/app-helpers'
import {
  renderAllQueriesResult,
  useInfinitePaginatedQuery,
} from 'lib/react-query-utils'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { MultipleAutocomplete } from 'ui/inputs/autocomplete'

export type FilterArr = Array<string>

type FilterState<T> = {
  value: T
  setValue: Dispatch<SetStateAction<T>>
}

type Props = {
  onClose: () => void
  filters: {
    statuses: FilterState<FilterArr>
    departments: FilterState<FilterArr>
    projects: FilterState<FilterArr>
    recruiters: FilterState<FilterArr>
    skills: FilterState<FilterArr>
    dateFrom: FilterState<Date | null>
    dateTo: FilterState<Date | null>
  }
  onFiltersApply: () => void
  onFiltersReset: () => void

  isSelectedFiltersEmpty?: boolean
}

export const JobsFilters = (props: Props) => {
  const { t } = useTranslation()

  const { dictionaries } = useDictionaries()
  const $projects = useQuery(qk.company.projects.toKey(), getCompanyProjects)
  const $departments = useQuery(
    qk.company.departments.toKey(),
    getCompanyDepartments,
  )

  const $recruiters = useInfinitePaginatedQuery(
    qk.auth.users.list,
    getUsersList,
    {
      pagination: { page: 0, pageSize: 50 },
      roles: ['OWNER', 'HR_MANAGER', 'RECRUITER'],
      statuses: ['ACTIVE'],
    },
  )

  const $skills = useInfinitePaginatedQuery(
    qk.company.skills.list,
    getCompanySkillsList,
    {
      pagination: { page: 0, pageSize: 50 },
      filters: { active: true },
    },
  )

  return (
    <Filters
      onClose={props.onClose}
      onApply={props.onFiltersApply}
      onReset={props.onFiltersReset}
      resetDisabled={props.isSelectedFiltersEmpty}
    >
      {renderAllQueriesResult([$projects, $departments, $recruiters, $skills], {
        success: ([projects, departments, recruiters, skills]) => {
          const recruiterOptions = recruiters.pages
            .flatMap(page => page.rows)
            .map(recruiter => ({
              value: recruiter.userId,
              label: recruiter.fullName,
            }))

          const skillOptions = skills.pages
            .flatMap(page => page.rows)
            .map(skill => ({
              value: skill.skillId,
              label: skill.name,
            }))

          return (
            <>
              {dictionaries.jobStatuses.options.length > 0 && (
                <MultipleAutocomplete
                  label={t('common.status')}
                  options={dictionaries.jobStatuses.options}
                  value={props.filters.statuses.value}
                  onChange={props.filters.statuses.setValue}
                  limitTags={2}
                />
              )}

              <MultipleAutocomplete
                label={t('common.department')}
                options={departments.map(department => ({
                  value: department.departmentId,
                  label: department.name,
                }))}
                value={props.filters.departments.value}
                onChange={props.filters.departments.setValue}
                limitTags={2}
              />

              <MultipleAutocomplete
                label={t('common.project')}
                options={projects
                  .filter(project => !project.closed)
                  .map(project => ({
                    value: project.projectId,
                    label: project.name,
                  }))}
                value={props.filters.projects.value}
                onChange={props.filters.projects.setValue}
                limitTags={2}
              />

              {recruiterOptions.length > 0 && (
                <MultipleAutocomplete
                  label={t('common.recruiter')}
                  options={recruiterOptions}
                  value={props.filters.recruiters.value}
                  onChange={props.filters.recruiters.setValue}
                  limitTags={2}
                />
              )}

              {skillOptions.length > 0 && (
                <MultipleAutocomplete
                  label={t('common.skill')}
                  options={skillOptions}
                  value={props.filters.skills.value}
                  onChange={props.filters.skills.setValue}
                  limitTags={2}
                />
              )}

              <DateRangeGroup
                title={t('common.creation_date')}
                dateFrom={props.filters.dateFrom.value}
                onChangeDateFrom={props.filters.dateFrom.setValue}
                dateTo={props.filters.dateTo.value}
                onChangeDateTo={props.filters.dateTo.setValue}
                maxDate={new Date()}
              />
            </>
          )
        },
      })}
    </Filters>
  )
}
