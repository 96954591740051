import { alpha, Box, Drawer, useTheme } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  filtersContent: React.ReactNode
  children: ReactNode
  filtersPosition?: 'left' | 'right'
}

const FILTERS_SIDEBAR_WIDTH = 320

export const FiltersTemplate = ({
  filtersPosition = 'right',
  filtersContent,
  children,
}: Props) => {
  const theme = useTheme()

  return (
    <Box display="flex" flex="auto" minHeight={0}>
      {filtersPosition === 'left' && (
        <Drawer
          open
          anchor="left"
          variant="persistent"
          sx={{
            width: `${FILTERS_SIDEBAR_WIDTH}px`,
            zIndex: 1000,
            '& .MuiDrawer-paper': {
              position: 'static',
              width: `${FILTERS_SIDEBAR_WIDTH}px`,
              border: 'none',
              borderRadius: '0px',

              boxShadow: `-2px -2px 2px 0px ${alpha('#000000', 0.05)}`,
              borderRight: `1px solid ${theme.palette.divider}`,
            },
          }}
        >
          {filtersContent}
        </Drawer>
      )}

      <Box
        sx={{
          position: 'relative',

          flex: 'auto',
          minWidth: 0,
          marginLeft: 0,
          transition: theme =>
            theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        <Box height="100%">{children}</Box>
      </Box>

      {filtersPosition === 'right' && (
        <Drawer
          open
          anchor="left"
          variant="persistent"
          sx={{
            width: `${FILTERS_SIDEBAR_WIDTH}px`,
            zIndex: 1000,

            '& .MuiDrawer-paper': {
              position: 'static',
              width: `${FILTERS_SIDEBAR_WIDTH}px`,
              border: 'none',
              borderRadius: '0px',

              boxShadow: `-2px -2px 2px 0px ${alpha('#000000', 0.05)}`,
            },
          }}
        >
          {filtersContent}
        </Drawer>
      )}
    </Box>
  )
}
