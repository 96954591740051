import { Box, Stack } from '@mui/material'
import { IconClear, IconSettings04 } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { InlineTab, InlineTabs } from 'ui/navigation'

import { FiltersIconButton } from './filters-icon-button'

type Props = {
  children: React.ReactNode
  onApply: () => void
  onReset: () => void
  resetDisabled?: boolean
  onClose?: () => void
  actions?: React.ReactNode
}

export const Filters = (props: Props) => {
  const { t } = useTranslation()

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
        pt="6px"
        px="16px"
        borderBottom="1px solid #E5E5E8"
      >
        <InlineTabs value="Filters">
          <InlineTab
            label={
              <Box display="flex" justifyContent="center" width="44px">
                <IconSettings04 sx={{ width: '20px', height: '20px' }} />
              </Box>
            }
            value="Filters"
          />
        </InlineTabs>
      </Box>

      <Stack overflow="auto" flex="auto" spacing={2} padding={2}>
        {props.children}
      </Stack>

      {props.onApply && (
        <Box mt="auto">
          <Stack direction="row" p={1.5} spacing={1}>
            <FiltersIconButton
              title={t('common.reset_filters')}
              onClick={props.onReset}
              iconComponent={IconClear}
              disabled={props.resetDisabled}
            />

            {props.actions}

            <Button fullWidth onClick={props.onApply} size="large">
              {t('common.apply')}
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  )
}
