import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconTrashOutlined = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M6.47656 5.5C6.60917 5.5 6.73635 5.55268 6.83012 5.64645C6.92388 5.74021 6.97656 5.86739 6.97656 6V12C6.97656 12.1326 6.92388 12.2598 6.83012 12.3536C6.73635 12.4473 6.60917 12.5 6.47656 12.5C6.34395 12.5 6.21678 12.4473 6.12301 12.3536C6.02924 12.2598 5.97656 12.1326 5.97656 12V6C5.97656 5.86739 6.02924 5.74021 6.12301 5.64645C6.21678 5.55268 6.34395 5.5 6.47656 5.5ZM8.97656 5.5C9.10917 5.5 9.23635 5.55268 9.33012 5.64645C9.42388 5.74021 9.47656 5.86739 9.47656 6V12C9.47656 12.1326 9.42388 12.2598 9.33012 12.3536C9.23635 12.4473 9.10917 12.5 8.97656 12.5C8.84395 12.5 8.71678 12.4473 8.62301 12.3536C8.52924 12.2598 8.47656 12.1326 8.47656 12V6C8.47656 5.86739 8.52924 5.74021 8.62301 5.64645C8.71678 5.55268 8.84395 5.5 8.97656 5.5ZM11.9766 6C11.9766 5.86739 11.9239 5.74021 11.8301 5.64645C11.7363 5.55268 11.6092 5.5 11.4766 5.5C11.344 5.5 11.2168 5.55268 11.123 5.64645C11.0292 5.74021 10.9766 5.86739 10.9766 6V12C10.9766 12.1326 11.0292 12.2598 11.123 12.3536C11.2168 12.4473 11.344 12.5 11.4766 12.5C11.6092 12.5 11.7363 12.4473 11.8301 12.3536C11.9239 12.2598 11.9766 12.1326 11.9766 12V6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4766 3C15.4766 3.26522 15.3712 3.51957 15.1837 3.70711C14.9961 3.89464 14.7418 4 14.4766 4H13.9766V13C13.9766 13.5304 13.7658 14.0391 13.3908 14.4142C13.0157 14.7893 12.507 15 11.9766 15H5.97656C5.44613 15 4.93742 14.7893 4.56235 14.4142C4.18728 14.0391 3.97656 13.5304 3.97656 13V4H3.47656C3.21135 4 2.95699 3.89464 2.76946 3.70711C2.58192 3.51957 2.47656 3.26522 2.47656 3V2C2.47656 1.73478 2.58192 1.48043 2.76946 1.29289C2.95699 1.10536 3.21135 1 3.47656 1H6.97656C6.97656 0.734784 7.08192 0.48043 7.26946 0.292893C7.45699 0.105357 7.71135 0 7.97656 0L9.97656 0C10.2418 0 10.4961 0.105357 10.6837 0.292893C10.8712 0.48043 10.9766 0.734784 10.9766 1H14.4766C14.7418 1 14.9961 1.10536 15.1837 1.29289C15.3712 1.48043 15.4766 1.73478 15.4766 2V3ZM5.09456 4L4.97656 4.059V13C4.97656 13.2652 5.08192 13.5196 5.26946 13.7071C5.45699 13.8946 5.71135 14 5.97656 14H11.9766C12.2418 14 12.4961 13.8946 12.6837 13.7071C12.8712 13.5196 12.9766 13.2652 12.9766 13V4.059L12.8586 4H5.09456ZM3.47656 3V2H14.4766V3H3.47656Z"
      fill="currentColor"
    />
  </SvgIcon>
)
