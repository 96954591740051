import * as t from 'io-ts'

export const TRoleId = t.union([
  t.literal('OWNER'),
  t.literal('HR_MANAGER'),
  t.literal('RECRUITER'),
  t.literal('INTERVIEWER'),
  t.literal('HIRING_MANAGER'),
  t.literal('UNCONFIRMED_USER'),
  t.literal('UNCOMPANY_USER'),
])

export type RoleId = t.TypeOf<typeof TRoleId>

export const TRolesList = t.array(
  t.strict({
    code: TRoleId,
    name: t.string,
  }),
)
