import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

const TThreadParticipant = t.intersection([
  t.strict({
    emails: t.array(t.string),
  }),
  t.partial({
    id: t.string,
    fullName: t.string,
    imageUrl: t.string,
  }),
])

export type ThreadParticipant = t.TypeOf<typeof TThreadParticipant>

const TThreadStatus = t.union([t.literal('SEEN'), t.literal('NOT_SEEN')])

export type ThreadStatus = t.TypeOf<typeof TThreadStatus>

export const TMailboxThread = t.strict({
  threadId: t.string,
  messagesNumber: t.number,
  status: TThreadStatus,
  subject: t.string,
  latestMessageBody: t.string,
  latestMessageCreatedAt: DateFromISOString,
  messagingParticipants: t.array(TThreadParticipant),
})

export type MailboxThread = t.TypeOf<typeof TMailboxThread>

const TMessageAttachment = t.strict({
  fileId: t.string,
  filename: t.string,
  size: t.number,
  contentDispositionType: t.string,
})

export type MessageAttachment = t.TypeOf<typeof TMessageAttachment>

const TThreadMessage = t.strict({
  id: t.string,
  body: t.string,
  bodyPreview: t.string,
  createdAt: DateFromISOString,
  from: TThreadParticipant,
  to: t.array(TThreadParticipant),
  attachments: t.array(TMessageAttachment),
})

export type ThreadMessage = t.TypeOf<typeof TThreadMessage>

export const TMailboxThreadMessages = t.strict({
  threadId: t.string,
  subject: t.string,
  messages: t.array(TThreadMessage),
})

export const TMailboxEmailsCount = t.strict({
  count: t.number,
})
