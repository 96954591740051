import { alpha, Box, Fade } from '@mui/material'
import { ReactNode } from 'react'

type Props = Readonly<{
  isFullScreen: boolean
  children: ReactNode
}>

export const ResizableWindowContainer = ({ isFullScreen, children }: Props) => {
  return (
    <Box>
      <Fade in={isFullScreen}>
        <Box
          bgcolor={isFullScreen ? alpha('#000000', 0.6) : 'transparent'}
          width="100%"
          height="100vh"
          position="absolute"
          left={0}
          top={0}
          zIndex={1200}
        />
      </Fade>

      <Box
        sx={{
          position: 'fixed',
          transition: '0.2s',
          bottom: isFullScreen ? '50%' : 0,
          right: isFullScreen ? '50%' : 100,
          width: isFullScreen ? 820 : 584,
          transform: isFullScreen ? 'translate(50%, 50%)' : undefined,
          bgcolor: '#FFFFFF',
          border: 1,
          borderColor: 'divider',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          boxShadow:
            '0px 1px 1px rgba(0, 0, 0, 0.05), 0px 5px 15px rgba(0, 14, 62, 0.16)',
          zIndex: theme => theme.zIndex.modal,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
