import { paths } from 'app/paths'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { Link, Tab, Tabs } from 'ui/navigation'

type Props = Readonly<{
  contactId: string
}>

export const ContactHeaderNavigation = ({ contactId }: Props) => {
  const [searchParams] = useSearchParams()
  const detailsPath = generatePath(paths.contactDetails, { contactId })
  const historyPath = generatePath(paths.contactHistory, { contactId })

  const { t } = useTranslation()

  return (
    <Tabs value={location.pathname}>
      <Tab
        component={Link}
        to={{
          pathname: detailsPath,
          search: `?${searchParams.toString()}`,
        }}
        value={detailsPath}
        label={t('common.details')}
      />

      <Tab
        component={Link}
        to={{
          pathname: historyPath,
          search: `?${searchParams.toString()}`,
        }}
        value={historyPath}
        label={t('common.history')}
      />
    </Tabs>
  )
}
