import {
  alpha,
  styled,
  Tab as MuiTab,
  tabClasses,
  TabProps as MuiTabProps,
} from '@mui/material'

export interface StyledTabProps extends MuiTabProps {
  enableHover?: boolean
}

const StyledTab = styled(props => (
  <MuiTab disableRipple {...props} />
))<StyledTabProps>(({ theme, enableHover }) => ({
  ...theme.typography.body1,
  minWidth: 'auto',
  minHeight: 'auto',
  zIndex: 1,
  marginRight: theme.spacing(1),
  textTransform: 'none',
  color: theme.palette.mineShaft[800],
  height: 30,

  transition: 'color 0.5s',
  borderRadius: 45,

  ...(enableHover && {
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.mineShaft.main, 0.2)}`,
    },
  }),

  [`&.${tabClasses.selected}`]: {
    color: '#FFFFFF',
  },
}))

export const Tab = <C extends React.ElementType>(
  props: MuiTabProps<C, { component?: C; enableHover?: boolean }>,
) => {
  return <StyledTab {...props} />
}
